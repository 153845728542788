
import { Integration, TrendyolCredentials } from "@/dtos/Integration";
import { defineComponent, ref, Ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import { IntegrationProduct } from "@/dtos/IntegrationProduct";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { IIntegrationElement } from "@/dtos/IIntegrationElement";
import CreateUpdateIntegration from "@/components/modals/forms/CreateUpdateIntegration.vue";


export default defineComponent({
  name: "kt-widget-5",
  components: { CreateUpdateIntegration },
  props: {
    widgetClasses: String,
    data: Array
  },
  emits: ["need-refresh"],
  setup(props,ctx) {
    const store = useStore();
    const selectedIntegration: Ref<Integration> = ref({});
    const deleteIntegration = async (item: Integration) => {
      Swal.fire({
        title: "Emin misiniz?",
        text: `${item.id} numaralı ${IIntegrationElement.friendlyName({integrationId:item.id,integrationType:item.integrationType})} entegrasyonunu silmek istediğinize emin misiniz ? Bu işlem geri alınamaz.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet Sil!",
        cancelButtonText : "Vazgeç",
        showLoaderOnConfirm: true,
        preConfirm: ()=> {

        Swal.showLoading();
        return store.dispatch(Actions.DELETE_INTEGRATION, item).then((r) => {
        
          Swal.fire({
            title: "Silindi!",
            text: "Entegrasyon başarıyla silindi!",
            icon: "success"
          }).then(() => {
            ctx.emit("need-refresh");
          });
        }).catch((e)=>{
          Swal.fire({
            title: "Hata",
            text: "Silinirken sorun çıktı",
            icon: "error",
            confirmButtonText: "Tamam"
          });
        
      });

        }
      });
    };

    return { Integration, IntegrationProduct, selectedIntegration, deleteIntegration }
  }
});
